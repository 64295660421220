var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h2',{staticClass:"mb-4"},[_vm._v("Список пользователей")]),_c('user-filters',{staticClass:"mb-5",attrs:{"table-params":_vm.tableParams},on:{"change-field":_vm.changeField}}),_c('user-export',{staticClass:"mb-5",attrs:{"adapted-params":_vm.adaptedParams}}),_c('data-table',{ref:"dataTable",attrs:{"url":"users","headers":_vm.headers,"table-params":_vm.adaptedParams},scopedSlots:_vm._u([{key:"item.email",fn:function(ref){
var user = ref.item;
return [_c('router-link',{attrs:{"to":{ name: 'users-id', params: { id: user.id } }}},[_vm._v(" "+_vm._s(user.email)+" ")])]}},{key:"item.isDeleted",fn:function(ref){
var user = ref.item;
return [_c('user-status',{attrs:{"deleted":user.isDeleted}})]}},{key:"item.role",fn:function(ref){
var user = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatRole(user))+" ")]}},{key:"item.buyerStatus",fn:function(ref){
var user = ref.item;
return [_c('nobr',[_vm._v(_vm._s(_vm.formatBuyerStatus(user)))])]}},{key:"item.subscription.createdAt",fn:function(ref){
var user = ref.item;
return [_c('nobr',[_vm._v(_vm._s(_vm._f("date")(user.subscription.createdAt))+" ")])]}},{key:"item.subscription.product",fn:function(ref){
var user = ref.item;
return [_c('nobr',[_vm._v(_vm._s(_vm.formatTariff(user.subscription.product))+" ")])]}},{key:"item.subscription.expiredAt",fn:function(ref){
var user = ref.item;
return [_c('nobr',[_vm._v(_vm._s(_vm._f("date")(user.subscription.expiredAt))+" ")])]}},{key:"item.totalPayments",fn:function(ref){
var user = ref.item;
return [_c('nobr',[_vm._v(_vm._s(_vm._f("number")(user.totalPayments))+" ₽")])]}},{key:"item.invite",fn:function(ref){
var user = ref.item;
return [(user.invite)?_c('nobr',{attrs:{"color":"success"}},[_vm._v(_vm._s(user.invite.id))]):_vm._e()]}},{key:"item.createdAt",fn:function(ref){
var user = ref.item;
return [_c('nobr',[_vm._v(_vm._s(_vm._f("date")(user.createdAt)))])]}},{key:"item.actions",fn:function(ref){
var user = ref.item;
return [_c('nobr',[_c('v-btn',{staticClass:"mr-2",attrs:{"icon":"","color":"accent","to":{ name: 'users-id', params: { id: user.id } }}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-pencil")])],1),(!user.isDeleted)?_c('v-btn',{attrs:{"icon":"","color":"error"},on:{"click":function($event){return _vm.deleteUser(user)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-delete")])],1):_vm._e()],1)]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }